<template>
  <div class="position-relative max-height-table">
    <LayoutDataTable
      :isLoading="showTable"
      :searchQuery="searchQuery"
      :perPage="perPage"
      :meta="dataMeta"
      :currentPage="currentPage"
      :totals="totals"
      :searchPlaceholder="$t('lbl_search_name__brand_or_sku')"
      @setPerPage="(value) => (perPage = value)"
      @setCurrentPage="(value) => (currentPage = value)"
      @setSearchQuery="(value) => (searchQuery = value)"
    >
      <template #default>
        <b-row class="w-full px-2 d-flex">
          <b-col>
            <b-row class="d-flex">
              <b-col style="min-width: 270px">
                <b-form-datepicker
                  :locale="$i18n.locale"
                  v-bind="$getCalendarLabel($i18n.locale)"
                  id="start-date"
                  :placeholder="$t('lbl_select_start_date')"
                  v-model="fromDate"
                  class="mb-2 mr-2"
                  today-button
                  reset-button
                ></b-form-datepicker>
              </b-col>
              <b-col style="min-width: 270px">
                <b-form-datepicker
                  :locale="$i18n.locale"
                  v-bind="$getCalendarLabel($i18n.locale)"
                  id="end-date"
                  :placeholder="$t('lbl_select_end_date')"
                  v-model="toDate"
                  class="mb-2"
                  today-button
                  reset-button
                ></b-form-datepicker>
              </b-col>
              <b-col v-if="!isProcessor" style="min-width: 400px">
                <b-row class="d-flex mb-2">
                  <b-col style="min-width: 175px">
                    <v-select
                      class="bg-white cs-select"
                      :value="selectedType"
                      @input="setSelectedType"
                      :clearable="false"
                      :options="PRODUCT_OPTIONS"
                    >
                      <template #selected-option="props">
                        <span>
                          {{
                          getLabelSelected(props, PRODUCT_OPTIONS)
                          }}
                        </span>
                      </template>
                    </v-select>
                  </b-col>
                  <b-col style="min-width: 175px">
                    <v-select
                      class="bg-white cs-select"
                      :value="selectedStatus"
                      @input="setSelectedStatus"
                      :clearable="false"
                      :options="ORDER_STATUS_OPTIONS"
                    >
                      <template #selected-option="props">
                        <span>
                          {{
                          getLabelSelected(props, ORDER_STATUS_OPTIONS)
                          }}
                        </span>
                      </template>
                    </v-select>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="2">
            <b-button block @click="handleExportToCSV" variant="primary">
              <span class="text-nowrap">{{ $t("lbl_export_csv") }}</span>
            </b-button>
          </b-col>
        </b-row>
        <b-table
          id="ReportTable"
          ref="refReportTable"
          class="position-relative cs-table"
          :items="fetchReportsList"
          responsive
          :fields="tableColumns"
          primary-key="item.id"
          show-empty
          :sort-desc.sync="isSortDirDesc"
          :sort-by.sync="sortBy"
          hover
          :empty-text="$t('lbl_no_matching_records_found')"
        >
          <template #cell(sku)="scope">
            <span class="d-inline-block">
              <u>{{ scope.item.sku || appUtils.randomCode(10) }}</u>
            </span>
          </template>
          <template #cell(order_code)="scope">
            <span class="d-inline-block">
              <u>{{ scope.item.order_code }}</u>
            </span>
          </template>
          <template #cell(name)="scope">
            <span
              v-b-tooltip.hover.top
              :title="scope.item.name"
              class="d-inline-block text-truncate"
              style="max-width: 150px"
            >{{ scope.item.name }}</span>
          </template>
          <template #cell(order_date)="scope">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 150px"
            >{{ formatDateDMY(scope.item.order_date) || "" }}</span>
          </template>
          <template #cell(supplier_order_updated_at)="scope">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 150px"
            >{{ formatDateDMY(scope.item.supplier_order_updated_at) || "" }}</span>
          </template>
          <template #cell(payment)="scope">
            <div class="d-flex items-center justify-center" style="max-width: 150px">
              <span
                class="d-inline-block text-truncate"
                style="max-width: 150px"
              >{{ scope.item.payment || $t("lbl_cash") }}</span>
            </div>
          </template>
          <template #cell(total_after_discount_price)="scope">
            <div class="d-flex items-center justify-center" style="max-width: 150px">
              <span class="d-inline-block text-truncate" style="max-width: 150px">
                {{
                appUtils.numberFormat(
                scope.item.total_after_discount_price,
                "VND"
                )
                }}
              </span>
            </div>
          </template>
          <template #cell(discount_amount)="scope">
            <div class="d-flex items-center justify-center" style="max-width: 150px">
              <span class="d-inline-block text-truncate" style="max-width: 150px">
                {{
                scope.item.discount_amount
                ? appUtils.numberFormat(scope.item.discount_amount, "VND")
                : "-"
                }}
              </span>
            </div>
          </template>
          <template #cell(owner_name)="scope">
            <div class="d-flex items-center justify-center" style="width: 200px">
              <span class="d-inline-block">{{ scope.item.owner_name }}</span>
            </div>
          </template>
          <template #cell(voucher_code)="scope">
            <div class="d-flex items-center justify-center" style="max-width: 150px">
              <span
                class="d-inline-block text-truncate"
                style="max-width: 150px"
              >{{ scope.item.voucher_code || "" }}</span>
            </div>
          </template>
          <template #cell(status)="scope">
            <div class="d-flex items-center justify-center">
              <b-badge
                :variant="getOrderStatus(scope.item.status).color"
              >{{ getOrderStatus(scope.item.status).text }}</b-badge>
            </div>
          </template>

          <template #cell(payment_method)="scope">
            {{
            scope.item.payment_method === 2
            ? "Thanh toán online"
            : "Thanh toán trực tiếp"
            }}
          </template>
          <template #cell(payment_status)="scope">
            {{
            scope.item.payment_status === 2
            ? "Đã thanh toán"
            : scope.item.payment_status === 1
            ? "Chưa thanh toán"
            : ""
            }}
          </template>
          <template #cell(price)="scope">{{ appUtils.numberFormat(scope.item.price) }}</template>
          <template #cell(quantity)="scope">{{ appUtils.numberFormat(scope.item.qty) }}</template>
          <template #cell(total)="scope">{{ appUtils.numberFormat(scope.item.total_price) }}</template>
        </b-table>
      </template>
    </LayoutDataTable>
    <div
      class="d-flex justify-content-between align-items-center px-2 py-1 tb-footer"
      style="background-color: #d9d9d9"
      v-if="!isProcessor"
    >
      <div
        class="font-weight-bold text-dark"
        style="font-size: 24px"
      >{{ $t("lbl_grand_total") }} (VND)</div>
      <div>
        <u class="text-dark" style="font-size: 24px">{{ appUtils.numberFormat(grandTotal) }}</u>
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BButton, BTooltip, BModal, BBadge } from 'bootstrap-vue'
import useReportsList from './useReportsList'
import LayoutDataTable from '../../layouts/table/LayoutDataTable.vue'
import appUtils from '@/utils/appUtils'
import { PRODUCT_ON_HODO, PRODUCT_TYPES } from '@/utils/constant'
import vSelect from 'vue-select'
import HODO_LOGO_BASE64 from '@/utils/logoHodoBase64.js'
import ExcelJS from 'exceljs'

export default {
  name: 'ReportsList',
  components: {
    LayoutDataTable,
    BTable,
    BButton,
    // BTooltip,
    // BModal,
    BBadge,
    vSelect
  },
  data() {
    return {
      appUtils,
      PRODUCT_TYPES,
      PRODUCT_ON_HODO
    }
  },
  methods: {
    setSelectedStatus(selectedOption) {
      this.indexSelectedStatus = this.ORDER_STATUS_OPTIONS.findIndex(
        item => item.value === selectedOption.value
      )
    },
    setSelectedType(selectedOption) {
      this.indexSelectedType = this.PRODUCT_OPTIONS.findIndex(
        item => item.value === selectedOption.value
      )
    },
    getLabelSelected(data, list_options) {
      return list_options?.find(item => item?.value === data?.value)?.label
    },
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 500)
    },
    formatDate(time) {
      // eslint-disable-next-line no-undef
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    formatDateDMY(time) {
      // eslint-disable-next-line no-undef
      return moment(time).format('DD/MM/YYYY')
    },
    handleExportToCSV() {
      if (this.isProcessor) {
        // this.$refs.refReportTable.tableExport({ type: "csv" });
        this.exportProcessorReport('Report Sale', this.dataList)
      } else {
        this.exportSaleReport()
      }
    },
    exportProcessorReport(filename, rows) {
      const csvHeader = [
        'SKU',
        this.$t('lbl_product_name'),
        this.$t('lbl_quantity')
      ]
      const csvBody = item => {
        const data = [
          item.sku || appUtils.randomCode(10),
          item.name,
          appUtils.numberFormat(item.qty)
        ]
        return data
      }
      const csvString = [
        csvHeader,
        ...rows.map(item =>
          csvBody(item).map(str =>
            str ? `"${str.toString().replace(/"/g, '"')}"` : ''
          )
        )
      ]
        .map(e => e.join(','))
        .join('\n')

      const BOM = '\uFEFF'
      var blob = new Blob([BOM + csvString], {
        type: 'text/csv;charset=utf-8'
      })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename)
      } else {
        var link = document.createElement('a')
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', filename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    },
    async exportSaleReport() {
      // Get data sale report from partner
      const res = await this.$rf.getRequest('CommonRequest').getSaleReport({
        keyword: this.searchQuery,
        page_size: 10000,
        page_num: 1,
        supplier_id: appUtils.getLocalUser()?.supplierInfo?.id,
        fromdate: this.fromDate,
        todate: this.toDate,
        status: this.selectedStatus.value,
        type: this.selectedType.value
      })
      const saleReportData = res.data.data.data

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Sheet1')

      // Add Hodo logo image
      const imageId = workbook.addImage({
        base64: HODO_LOGO_BASE64,
        extension: 'png'
      })
      worksheet.addImage(imageId, 'A1:A3')

      // Access columns from B to J and set their widths
      const defaultColWidth = 22
      for (let index = 1; index <= 10; index++) {
        const column = worksheet.getColumn(index + 1) // Columns are 1-indexed
        column.width = defaultColWidth
      }

      // Add a additional rows and merged cells with style before main table
      const companyNameCell = worksheet.getCell('B1')
      companyNameCell.value = 'CÔNG TY CỔ PHẦN CÔNG NGHỆ 905 VIỆT NAM'
      companyNameCell.font = { size: 13 }
      worksheet.mergeCells('B1:D1')

      const companyAddressCell = worksheet.getCell('B2')
      companyAddressCell.value =
        'Tầng 8, tòa nhà ACCI, số 210 Lê Trọng Tấn, Khương Mai, Thanh Xuân, Hà Nội'
      companyAddressCell.font = { size: 13 }
      worksheet.mergeCells('B2:E2')

      const companyContactCell = worksheet.getCell('B3')
      companyContactCell.value =
        'Tel: 0345.900.905                    Website: https://hodo.com.vn/'
      companyContactCell.font = { size: 13 }
      worksheet.mergeCells('B3:D3')

      const titleCell = worksheet.getCell('E5')
      titleCell.value = 'CHI TIẾT ĐƠN HÀNG TRÊN SÀN THƯƠNG MẠI ĐIỆN TỬ HODO'
      titleCell.font = { size: 14, bold: true }
      worksheet.mergeCells('E5:H5')

      const fromDate = worksheet.getCell('D6')
      fromDate.value = 'Từ ngày:'
      const fromDateValueCell = worksheet.getCell('E6')
      fromDateValueCell.value = this.fromDate
        ? window.moment(this.fromDate).format('DD/MM/YYYY')
        : ''

      const toDate = worksheet.getCell('G6')
      toDate.value = 'Đến ngày:'
      const toDateValueCell = worksheet.getCell('H6')
      toDateValueCell.value = this.toDate
        ? window.moment(this.toDate).format('DD/MM/YYYY')
        : ''

      // Add additional rows with bold style
      const customerData = [
        [
          '',
          'Tên nhà cung cấp:',
          appUtils.getLocalUser()?.supplierInfo?.name || '',
          '',
          '',
          '',
          '',
          'Tổng doanh thu:',
          appUtils.numberFormat(this.grandTotal) + ' VND'
        ],
        [
          '',
          'Mã số:',
          appUtils.getLocalUser()?.supplierInfo?.ein || '',
          '',
          '',
          '',
          ''
        ],
        ['', 'Địa chỉ:', appUtils.getLocalUser()?.supplierInfo?.address || ''],
        []
      ]
      worksheet.addRows(customerData)

      // Generate the main table (your data) with borders and style
      // let completedOrders = saleReportData.filter(order =>
      //   ['3', '4'].includes(order.status)
      // )
      let completedOrders = saleReportData
      const rawData = completedOrders.map((order, i) => {
        return [
          i + 1,
          order?.order_code || '',
          order?.order_date
            ? window.moment(order?.order_date).format('DD/MM/YYYY')
            : '',
          order?.supplier_order_updated_at
            ? window
                .moment(order?.supplier_order_updated_at)
                .format('DD/MM/YYYY')
            : '',
          this.getOrderStatus(order?.status).text || '',
          order?.name || '',
          Object.prototype.hasOwnProperty.call(order, 'order_code')
            ? order?.payment_method === 2
              ? 'Thanh toán online'
              : 'Thanh toán trực tiếp' || this.$t('lbl_cash')
            : '',
          order?.total_after_discount_price
            ? appUtils.numberFormat(order?.total_after_discount_price)
            : 0,
          order?.discount_amount
            ? appUtils.numberFormat(order?.discount_amount)
            : 0,
          order?.voucher_code || ''
        ]
      })

      const mainTableData = [
        [
          'STT',
          'MÃ ĐƠN HÀNG',
          'NGÀY TẠO ĐƠN',
          'NGÀY KẾT THÚC ĐƠN HÀNG',
          'TRẠNG THÁI ĐƠN HÀNG',
          'TÊN DỊCH VỤ',
          'HÌNH THỨC THANH TOÁN',
          'TỔNG THANH TOÁN',
          'CHIẾT KHẤU ĐƠN HÀNG',
          'MÃ GIẢM GIÁ'
        ],
        ...rawData
      ]

      worksheet.addRows(mainTableData)
      const mainTable = worksheet.getRows(11, mainTableData.length)
      mainTable.forEach((row, rowIndex) => {
        if (rowIndex) {
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'hair' },
              left: { style: 'thin' },
              right: { style: 'thin' },
              bottom: { style: 'hair' }
            }
            cell.alignment = { horizontal: 'center', vertical: 'center' }
          })
        } else {
          row.eachCell(cell => {
            cell.font = { bold: true }
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              right: { style: 'thin' },
              bottom: { style: 'hair' }
            }
            cell.alignment = {
              horizontal: 'center',
              vertical: 'middle',
              wrapText: true
            }
          })
        }
      })
      const headerRow = worksheet.getRow(11)
      headerRow.height = 32

      // default styles
      workbook.eachSheet(sheet => {
        sheet.eachRow(row => {
          row.eachCell(cell => {
            if (!cell.font?.name) {
              cell.font = Object.assign(cell.font || {}, {
                name: 'Times New Roman'
              })
            }
          })
        })
      })
      // worksheet.getColumn(8).numFmt = '#.##0,00"đ"';
      // worksheet.getColumn(9).numFmt = '#.##0,00"đ"';
      worksheet.properties.defaultRowHeight = 16

      // Save the workbook to a file
      await workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Sale Report.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    getOrderStatus(status) {
      switch (status) {
        case '4':
          return {
            text: this.$t('CANCELED'),
            color: 'danger'
          }
        case '3':
          return {
            text: this.$t('FINISHED'),
            color: 'success'
          }

        default:
          return {
            text: this.$t('lbl_supplier_process_status.PROCESSING'),
            color: 'primary'
          }
      }
    }
  },
  setup() {
    const mixData = useReportsList()
    return { ...mixData }
  }
}
</script>

<style  scope lang="scss">
.max-height-table {
  max-height: calc(100vh - 160px - 2.2rem);
  overflow-x: hidden;
}
.b-table-details {
  background-color: #f6f6f9;
}

.cs-select {
  .vs__dropdown-toggle {
    height: 38px !important;
  }
}

.tb-footer {
  position: sticky;
  bottom: -1px;
  z-index: 4;
}

.cs-table {
  th,
  td {
    padding: 0.72rem 0.5rem;
  }
  tbody {
    min-height: 100vh;
  }
}
</style>
